<template>
	<div>
		<mt-header :title="getWord(['payment', 'page2'])" style="height:0.88rem;font-size: 0.35rem;">
			<router-link to="/money" slot="left">
				<mt-button icon="back">{{ getWord('back_to_previous') }}</mt-button>
			</router-link>
		</mt-header>
		<div class="money_finsh">

		</div>
		<p>{{amount}}</p>
		<router-link to="/home/">
			<mt-button size="large">{{ getWord(['done','recharge']) }}</mt-button>
		</router-link>
	</div>
</template>
<style lang="scss" scoped>
	.money_finsh {
		background-image: url('/static/assets/images2/money/gou.png');
		width: 1.07rem;
		height: 1.07rem;
		background-size: 100%;
		margin: 2.17rem auto;

	}
</style>
<script>
	export default {
		data() {
			return {
				amount: '',
				props: {
					
				}
			};
		},
		methods: {

		},
	}
</script>
